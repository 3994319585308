@import "../scss/scss.scss";

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* Above is CSS Reset */

/********************************************************************** Background Image ************************************************************************/
body {
  color: black;
}

img {
  max-width: 100%;
}

/**********************************************************************  vvv NavBar Section vvv **********************************************************************/
.navbar{
  display: flex;
  justify-content: space-around;
  padding: 2%;
  font-family: $home-primary;
  background-color:#d3d3d3;
}

.link {
  color: whitesmoke;
  text-decoration: inherit;
  font-size: 2.3rem;
  
}

.link:hover {
  // color: #558B6E;
  color: $nav-form;
}
/**********************************************************************  vvv Header Section **********************************************************************/
.header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  // background-color:#d3d3d3;
  background-image: url("../../public/images/bg2.jpeg");
  background-size: cover;
  @include media-query($small) {
    // background-color: orange;
    height: 18rem;
  }
}

.header-title {
  font-size: 5.2rem;
  font-family: $home-primary;
  @include media-query($small) {
    font-size:1.9rem;
  }
} 

.header-subtitle {
  font-size: 2.2rem;
  font-family: $home-subtitle;
  @include media-query($small) {
    font-size:1.1rem;
  }
}

.header-txt-container {
  display: flex;
  align-items: center;
  gap: 35px;
  @include media-query($small) {
    // background-color: aqua;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.socials {
  margin-top: 20px;
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
}

.social-container{
  color:black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.social-icon{
  font-size: 2rem;
  color:black;
  transition: transform .5s ease;
  // text-decoration: black;
}

.social-icon:hover {
  transform:scale(1.2);
  box-shadow: 0 0 20px 3px rgba(98, 95, 95, 0.656);
}

.body-container {
  background-image: url("../../public/images/porkys-exit.jpeg");
  background-size: cover;
}

.about-me-container {
  display: flex;
  justify-content: center;
  gap: 100px;
  color:#d3d3d3;
  // background-image: url("../../public/images/dark-wave.jpeg");
  // background-color: rgb(1, 16, 16);
  // background-size: cover;
  @include media-query($small) {
    // background-color: #116399;
    flex-wrap: wrap;
    flex-direction:column-reverse;
    gap:20px;
  }
}

.about-txt-container {
  max-width: 1000px;
  width: 100%;
  padding: 30px;
  text-align: left;
  margin-top: 50px;
  @include media-query($small) {
    // background-color: red;
    padding: 0%;
    text-align: center;
  }
}

.about-me-title {
  font-size: 4.5rem;
  font-family: $home-primary;
  text-align: center;
  margin-bottom: 20px;
  // text-shadow: 3px 3px black;
  @include media-query($small){
    // background-color: rgb(1, 16, 16);
    width: 100%;
    color: #d3d3d3;
    font-size: 1.7rem;
    margin-bottom: 0px;
  }
}

.about-txt {
  // background-color: darkkhaki;
  font-size: 2.3rem;
  line-height: 4rem;
  font-family: $about-me;
  // text-shadow: 3px 3px black;
  @include media-query($small){
    // background-color: #b31b92;
    font-size: 1.4rem;
    line-height: 2.5rem;
    padding: 5%;
  }
}

.img-container {
  @include media-query($small){
    // background-color: lawngreen;
    width: 100%;
  }
  width: 25%;
}

.headshot {
  margin-top: 60px;
  width: 600px;
  // background-color: greenyellow;
  border-radius: 15%;
  -webkit-mask-image: radial-gradient(69% 80% at 50% 50%, black 55%, transparent 80%);
  @include media-query($small){
    width: 100px;
    display: none;
  }
}

/********************************************************************** vvv Home Page Section vvv **********************************************************************/

/********************************************************************** vvv Icons Section vvv **********************************************************************/

.skills-section{
  @include media-query($small){
    // background-color: #116399;
    margin-top: 3%;
  }
}

.skills-title {
  font-size: 4.5rem;
  font-family: $home-primary;
  text-align: center;
  margin: 100px 0px;
  text-shadow: 3px 3px black;
  color: #d3d3d3;
  // background-color: #116399;
  @include media-query($small){
    font-size: 2rem;
    margin: 0px;
    padding: 5%;
  }
}

.icons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  color: #d3d3d3;
  // background-color: red;
  text-align: center;
  @include media-query($small){
    flex-direction: row-reverse;
    // background-color: rgba(1, 16, 16, 0.753);
    // background-color: rgba(39, 39, 39, 0.753);
  }
}

.skill-card-container {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  justify-content: center;
  // background-color: orange;
  margin-bottom: 15px;
  @include media-query($small){
    // background-color: #38caef;
    width: auto;
    margin: 0%;
  }
}

// .skill-card {
//   // background-color: teal;
//   // height: 100px;
//   // width: 100px;
//   display: flex;
//   flex-wrap: wrap;
//   gap: 0px 25px;
// }

.skill-text {
  // background-color: coral;
  margin-bottom: 5%;
  height: 95px;
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background-color: rgb(1, 16, 16);
  border: 1px solid gray;
  @include media-query($small) {
    display: none;
  }
}

.skill-name {
  @include media-query($small) {
    display: none;
  }
}

// .skill-name-small-media {
//   display: none;
//   @include media-query($small) {
//     display: inline;
//   }
// }

.skill-name-popup {
  display: none;
  @include media-query($small) {
    display: inline;
  }
}

.skill-text-just-text {
  @include media-query($small) {
    display: none;
  }
}

.skill-img-container {
  // background-color: yellow;
  display: flex;
  justify-content: center;
  align-content: baseline;
}

.skill-img {
  // background-color: darkorchid;
  height: 100px;
  width: 100px;
}

/********************************************************************** vvv Form Section vvv **********************************************************************/

.form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
 position: relative;
 @include media-query($small) {
  // background-color: orange;
  padding: 0%;
 }
}

.get-in-touch-container{
  color: #d3d3d3;
  @include media-query($small) {
    // background-color: rgb(0, 255, 60);
    width: 100%;
   }
}

.get-in-touch {
  font-size: 4.5rem;
  font-family: $home-primary;
  text-align: center;
  margin: 100px 0px;
  text-shadow: 3px 3px black;
  @include media-query($small) {
    // background-color: rgb(212, 53, 93);
    font-size: 1.7rem;
    margin: 4% 0% 4% 0%;
   }
}


.contact-text span {
  font-size: 5rem;
  font-family: $home-primary;
}

 .contact-text-container {
  display:flex;
  flex-wrap: wrap;
}

.contact-text {
  color:$austin-primary;
  font-size: 1.5rem;
  font-family: $home-primary;
  width: 100%;
  max-width: 1200px;
  line-height: 3rem;
  text-align: center;
  margin: 50px 0;
  text-shadow: 3px 3px black;
  @include media-query($small) {
    // background-color: rgb(0, 166, 255);
    padding: 0% 5% 0% 5%;
    margin: 4% 0% 4% 0%;
   }
}

.contact-form {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @include media-query($small) {
    // background-color: rgb(80, 13, 215);
    width: 100%;
   }
}

.input-div {
  position: relative;
  padding: 20px 0 0;
  margin-top: 10px;
  width: 100%;
  max-width: 50%;
  @include media-query($small) {
    // background-color: rgb(11, 163, 37);
    padding: 4% 4% 2% 4%;
    max-width: 80%;
   }
}

.input-input {
  width: 100%;
  border: none;
  border-bottom: 2px solid darkgray;
  outline: 0;
  font-size: 1rem;
  color: $nav-form;
  background: transparent;
  transition: border-color 0.2s;
  padding-top: 15px;
}

.input-input::placeholder {
  color: transparent;
}

.input-input::placeholder-shown~.input-input {
  font-size: 2rem;
  cursor: text;
  top: 20px;
}

.label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1.7rem;
  color:$austin-primary;
  pointer-events: none;
  @include media-query($small) {
      font-size:1rem;
  }
}

.label:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #116399, #38caef);
  border-image-slice: 1;
}

.label:focus~.label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #38caef;
  font-weight: 700;
}

.label:required,
.label:invalid {
  box-shadow: none;
}

.btn-content {
  @include media-query($small) {
    // background-color: rgb(132, 7, 19);
    display:flex;
    justify-content: start;
    align-items: center;
    align-content: center;
    width: 40%;
   }
}

button {
  margin-top: 20px;
  font-size: 18px;
  color:$austin-primary;
  font-weight: 800;
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  text-transform: uppercase;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
  animation: blink 1s linear infinite;
  text-shadow: rgba(255, 215, 77, 0.6) -1px -1px 6px, rgba(124, 127, 255, 0.6) 1px 1px 6px;
  @include media-query($small) {
    margin: 20%;
    // padding: 8%;
   }
}

button:focus,
button:hover {
    color: #fff;
    animation: none;
}

button:focus:after,
button:hover:after {
    width: 100%;
    left: 0%;
}

button:after {
    content: "";
    pointer-events: none;
    bottom: -2px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: #fff;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
}

.animated {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/********************************************************************** vvv Game Section vvv **********************************************************************/
.games {
  text-align: center;
  @include media-query($small) {
    // margin-bottom: 2rem;
    // background-color: white;
  }
}

.games-title {
  font-size: 4rem;
  font-family: $home-primary;
  margin: 2rem 0rem 2rem 0rem;
  color: #d3d3d3;
  @include media-query($small) {
    font-size: 1.75rem;
  }
}

.game-images-container{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px 15px;
  @include media-query($small) {
    gap: 10px 
  }
}

.game-card-container {
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  justify-content: center;
  @include media-query($small) {
    width: 100%;
  }
}

.game-card {
  color: #d3d3d3;
  font-size: 1.5rem;
  padding-bottom: .75rem;
}

.game-img {
  border-radius: 2%;
  height: 320px;
  max-height: 400px;
  @include media-query($small) {
    height: auto;
  }
}

/*************************** vvv Popup Section vvv ***********************************************************************************************************************************/

.popup {
  position: relative;
}

/*************************** vvv Contact Section vvv ***********************************************************************************************************************************/

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 400px;
  color:#d3d3d3;
  // background-color: #116399;
  @include media-query($small) {
    height: 200px;
  }
}

.contact-title {
  font-size: 4rem;
  font-family: $home-primary;
  margin: 2rem 0rem 2rem 0rem;
  color: #d3d3d3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // background-color: darkred;
  @include media-query($small) {
    margin-top: 0rem;
    font-size: 2rem;
  }
}

.containt-info-container{
  // background-color: aquamarine;
  width: 100%;
  display: flex;
  justify-content: space-around;
  @include media-query($small) {
    // background-color: #116399;
    width: 85%;
    justify-content: center;
    flex-direction: column;
  }
}

.contact-info{
  // background-color: lightpink;
  font-size: 2rem;
  font-family: $home-primary;
  color: #d3d3d3;
  @include media-query($small) {
    font-size: 1.5rem;
  }
}
